<template>
    <section class="container-fluid">
        <titulo-divisor titulo="Servicios de talleres">
            <div class="row">
                <div class="col auto">
                    <el-input v-model="buscarListado" placeholder="Buscar servicios" size="small" @keypress.enter.native="listar" />
                </div>
                <div v-if="!contar" class="col-auto my-auto pl-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-else class="col-auto filtro-contador my-auto pl-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2">
                            <p class="f-12">{{ contar }}</p>
                            <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="limpiarFiltro" />
                        </div>
                    </div>
                </div>
                <div class="col-auto my-auto px-1">
                    <button class="btn btn-general f-12 px-4" @click="crearServicio">Crear servicio</button>
                </div>
            </div>
        </titulo-divisor>
        <div class="row listado-servicios-vigentes mx-0">
            <div v-for="data in servicios.data" :key="data.id" class="col-12 col-md-6 col-lg-4 col-xl-auto mb-3" @click="verServicio(data.id)">
                <card-servicio
                    :name="data.taller"
                    :section="data.seccion"
                    :configuration="data.configuracion"
                    :status="data.estado"
                    :date="data.fecha_finalizado"
                    :valueServicio="data.calificacion"
                    :percentage="data.porcentaje"
                />
            </div>
        </div>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-3">
                    <p class="input-label-top">Taller</p>
                    <el-select v-model="filtros.id_taller" clearable placeholder="Seleccionar taller" size="small" class="w-100">
                        <el-option v-for="item in select_talleres" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Estado</p>
                    <el-select v-model="filtros.id_estado" clearable placeholder="Seleccionar estado" size="small" class="w-100">
                        <el-option v-for="item in estado" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Tipo de servicio</p>
                    <el-select v-model="filtros.id_almacen_etapas_configuraciones_secciones" clearable placeholder="Seleccionar estado" size="small" class="w-100">
                        <el-option v-for="item in services" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" :disabled="contar == 0" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" :disabled="contar == 0" @click="filtrar"> Filtrar </button>
            </div>
        </modal-lateral>
        <modal-crear-servicio ref="modalCrearServicio" :id_proyecto="id_proyecto" />
    </section>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
export default {
    name: 'ServiciosTalleres',
    components:{
        modalCrearServicio: () => import('../partials/modalCrearServicio'),
    },
    data(){
        return{
            buscarListado:'',
            estado: [
                {
                    value: '0',
                    label: 'Finalizado'
                },
                {
                    value: '1',
                    label: 'En proceso'
                }
            ],
            cantFiltros: 0,
            id_proyecto: this.$route.params.id,
        }
    },
    watch: {
        async buscarListado(val) {
            if (val.length === 0) await this.listar()
        }
    },
    computed: {
        ...mapGetters({
            filtros: 'obras/servicios/services/filtros',
            select_talleres: 'selects/selects/select_talleres',
            select_configuraciones: 'selects/selects/select_configuraciones',
            select_configuraciones_secciones: 'selects/selects/select_configuraciones_secciones',
            servicios: 'obras/servicios/services/servicios',
			services: 'configurar/talleres/tipo_servicio/services'
        }),
        filter:{
            get(){ return this.filtros },
            set(val){ this.set_filtros(val) }
        },
        contar(){
            return this.filtros.count();
        }
    },
    async created () {
        await this.listar();
        await this.listarSelects()
    },
    methods:{
        ...mapActions({
            Action_get_selects_talleres: 'selects/selects/Action_get_selects_talleres',
            Action_get_selects_configuraciones: 'selects/selects/Action_get_selects_configuraciones',
            Action_get_selects_configuraciones_secciones: 'selects/selects/Action_get_selects_configuraciones_secciones',
            Action_limpiar_filtros: 'obras/servicios/services/Action_limpiar_filtros',
            Action_get_servicios: 'obras/servicios/services/Action_get_servicios',
			Action_get_services: 'configurar/talleres/tipo_servicio/Action_get_services'
        }),
        ...mapMutations({
            set_filtros: 'obtras/servicios/services/set_filtros'
        }),
        async listar(){
            await this.Action_get_servicios({ id_proyecto: this.id_proyecto, buscar: this.buscarListado })
        },
        async filtrar(){
            await this.Action_get_servicios({ id_proyecto: this.id_proyecto, buscar: this.buscarListado })
			this.$refs.abrirModalFiltros.toggle()
        },
        async listarSelects(){
            await this.Action_get_selects_talleres()
            await this.Action_get_selects_configuraciones({ id_proyecto: this.id_proyecto })
            await this.Action_get_services()
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        filtrarMateriales(){
            this.$refs.abrirModalFiltros.toggle()
            this.cantFiltros = 1
        },
        async limpiarFiltro(){
            this.Action_limpiar_filtros()
            await this.listar()
        },
        crearServicio(){
            this.$refs.modalCrearServicio.toggle()
        },
        verServicio(id_control_piso){
            this.$router.push({ name: 'obras.vigentes.lista.chequeo', params: { id_proyecto: this.id_proyecto, id_control_piso }})
        },
    }
}
</script>

<style>

</style>